/* react */
import * as React from "react";
import { FunctionComponent, useEffect, useState } from "react";

import { IAlertProps } from "./IAlert";
import "jquery-ui";
import { Common } from "../../lib/utils/common";
import { useHistory } from "react-router-dom";

import {
  Button,
  DataGrid,
  DateBox,
  LoadPanel,
  Popup,
  RadioGroup,
  SelectBox,
  TextArea,
  TextBox,
} from "devextreme-react";
import * as _ from "lodash";
import {
  Column,
  Editing,
  Pager,
  Paging,
  SearchPanel,
} from "devextreme-react/data-grid";
import { AdminDataProvider } from "../../lib/service/AdminDataProvider";
import $ from "jquery";
import moment from "moment";
import tBox from "devextreme/ui/text_box";
import sBox from "devextreme/ui/select_box";

import { alert as alt } from "devextreme/ui/dialog";
import { lang } from "../../lib/utils/language";
import { locale, loadMessages, formatMessage } from "devextreme/localization";
loadMessages(lang);
locale("" + localStorage.getItem("lang"));
export const Alert: FunctionComponent<IAlertProps> = (props) => {
  const history = useHistory();
  const common = new Common();
  const [alert, setAlert] = useState([] as any);
  const [alertHistory, setAlertHistory] = useState([] as any);
  const [alertValue, setAlertValue] = useState({} as any);
  const [alertDetail, setAlertDetail] = useState({} as any);

  const [txtDisabled, setTxtDisabled] = useState(true);
  const [startDate, setStartDate] = useState<Date | undefined>(undefined);
  const [endDate, setEndDate] = useState<Date | undefined>(undefined);
  const [gridFilterValue, setGridFilterValue] = useState<any>([]);
  /* componentDidMount && componentDidUpdate */
  useEffect(() => {
    const fetchArticles = async () => {
      common.ResizePage();
      const adminService = new AdminDataProvider();
      const main = await adminService.GetMainList();
      setAlert(main.data.alert);
      setAlertValue(main.data.alert[0]);
      const dt = main.data.alert[0];

      _dev("txtInp1", tBox).option("value", dt.systolicHigher);
      _dev("txtInp2", tBox).option("value", dt.systolicLower);
      _dev("txtInp3", tBox).option("value", dt.diastolicHigher);
      _dev("txtInp4", tBox).option("value", dt.diastolicLower);
      _dev("txtInp5", tBox).option("value", dt.bpHrHigher);
      _dev("txtInp6", tBox).option("value", dt.bpHrLower);
      _dev("txtInp7", tBox).option("value", dt.oxygenHigher);
      _dev("txtInp8", tBox).option("value", dt.oxygenLower);
      _dev("txtInp9", tBox).option("value", dt.oxHrHigher);
      _dev("txtInp10", tBox).option("value", dt.oxHrLower);
      _dev("txtInp11", tBox).option("value", dt.bodytempHigher);
      _dev("txtInp12", tBox).option("value", dt.bodytempLower);
      _dev("selTemp", sBox).option("value", dt.bodytempUnit);
    };

    if (localStorage.getItem("id") === "system") {
      history.push("/superAdmin");
    } else if (localStorage.getItem("jwt") !== null) {
      fetchArticles();
    } else {
      // history.push("/auth/login");
    }
  }, [props]);
  const onSearch = async () => {
    let filter = [["inputUtcDt", "between", [startDate, endDate]]];
    if (startDate !== undefined && endDate !== undefined) {
      console.log(filter);
      setGridFilterValue(filter);
    }
  };
  const onSDateChanged = async (e: any) => {
    setStartDate(e.value);
  };
  const onEDateChanged = async (e: any) => {
    setEndDate(e.value);
  };
  const _dev = (e: any, d: any) => {
    const id = document.getElementById(e) as HTMLElement;
    return d.getInstance(id);
  };
  const valueChanged = (e: any, num: any) => {
    if (e.previousValue !== "" && e.previousValue !== null) {
      if (e.previousValue !== e.value) {
        if (num > 6 && num < 11) {
          alertHistory.push("OX");
        } else if (num > 10) {
          alertHistory.push("TE");
        } else {
          alertHistory.push("BP");
        }
        setAlertHistory(alertHistory);
      }
    }
  };

  const btnSave = async (d: any) => {
    const adminService = new AdminDataProvider();

    const txtInp1 = _dev("txtInp1", tBox).option("value");
    const txtInp2 = _dev("txtInp2", tBox).option("value");
    const txtInp3 = _dev("txtInp3", tBox).option("value");
    const txtInp4 = _dev("txtInp4", tBox).option("value");
    const txtInp5 = _dev("txtInp5", tBox).option("value");
    const txtInp6 = _dev("txtInp6", tBox).option("value");
    const txtInp7 = _dev("txtInp7", tBox).option("value");
    const txtInp8 = _dev("txtInp8", tBox).option("value");
    const txtInp9 = _dev("txtInp9", tBox).option("value");
    const txtInp10 = _dev("txtInp10", tBox).option("value");
    const txtInp11 = _dev("txtInp11", tBox).option("value");
    const txtInp12 = _dev("txtInp12", tBox).option("value");

    const selTemp = _dev("selTemp", sBox).option("value");

    const json = {
      txtInp1: txtInp1,
      txtInp2: txtInp2,
      txtInp3: txtInp3,
      txtInp4: txtInp4,
      txtInp5: txtInp5,
      txtInp6: txtInp6,
      txtInp7: txtInp7,
      txtInp8: txtInp8,
      txtInp9: txtInp9,
      txtInp10: txtInp10,
      txtInp11: txtInp11,
      txtInp12: txtInp12,
      selTemp: selTemp,
      changeItem: _.uniq(alertHistory).join(","),
    };

    adminService.addAlert(json).then((e: any) => {
      alt(formatMessage("msg_changed"), "Save");
      history.push("/alert");
    });
  };
  return (
    <main id="content" className="content">
      <div className="pop-layer pop-layer--lg" id="popAlertLevel">
        <section className="pop-layer__wrap">
          <header>
            <h3>
              <strong>Alert Level</strong>
            </h3>
            <span className="icon icon--close lg popClose"></span>
          </header>

          <div className="cont">
            <div className="cont__wrap">
              <div className="update">
                <span className="info">
                  Last update :{" "}
                  {moment(alertDetail.inputUtcDt).format("MM/DD/YYYY hh:mm:ss")}
                </span>
              </div>
              <article className="mb20 mt20">
                <h4>
                  <i className="fa fa-medkit"></i>
                  <strong>Blood pressure</strong>
                </h4>
                <div className="level-wrap">
                  <div className="block">
                    <strong>Systolic</strong>
                    <div className="record-wrap">
                      <ul className="record">
                        <li>
                          <span className="txt">Higher</span>
                          <span className="sign">&#62;</span>
                          <span>{alertDetail.systolicHigher}</span>
                        </li>
                        <li>
                          <span className="txt">Lower</span>
                          <span className="sign">&#62;</span>
                          <span>{alertDetail.systolicLower}</span>
                        </li>
                      </ul>
                      <div className="unit">
                        <span>mmHg</span>
                      </div>
                    </div>
                  </div>
                  <div className="block">
                    <strong>Diastolic</strong>
                    <div className="record-wrap">
                      <ul className="record">
                        <li>
                          <span className="txt">Higher</span>
                          <span className="sign">&#62;</span>
                          <span>{alertDetail.diastolicHigher}</span>
                        </li>
                        <li>
                          <span className="txt">Lower</span>
                          <span className="sign">&#62;</span>
                          <span>{alertDetail.diastolicLower}</span>
                        </li>
                      </ul>
                      <div className="unit">
                        <span>mmHg</span>
                      </div>
                    </div>
                  </div>
                  <div className="block">
                    <strong>Heart Rate</strong>
                    <div className="record-wrap">
                      <ul className="record">
                        <li>
                          <span className="txt">Higher</span>
                          <span className="sign">&#62;</span>
                          <span>{alertDetail.bpHrHigher}</span>
                        </li>
                        <li>
                          <span className="txt">Lower</span>
                          <span className="sign">&#62;</span>
                          <span>{alertDetail.bpHrLower}</span>
                        </li>
                      </ul>
                      <div className="unit">
                        <span>bpm</span>
                      </div>
                    </div>
                  </div>
                </div>
              </article>

              <article className="mb20">
                <h4>
                  <i className="fa fa-heartbeat"></i>
                  <strong>SpO₂</strong>
                </h4>
                <div className="level-wrap">
                  <div className="block">
                    <strong>Oxygen Level</strong>
                    <div className="record-wrap">
                      <ul className="record">
                        <li>
                          <span className="txt">Higher</span>
                          <span className="sign">&#62;</span>
                          <span>{alertDetail.oxygenHigher}</span>
                        </li>
                        <li>
                          <span className="txt">Lower</span>
                          <span className="sign">&#62;</span>
                          <span>{alertDetail.oxygenLower}</span>
                        </li>
                      </ul>
                      <div className="unit">
                        <span>%</span>
                      </div>
                    </div>
                  </div>
                  <div className="block">
                    <strong>Heart Rate</strong>
                    <div className="record-wrap">
                      <ul className="record">
                        <li>
                          <span className="txt">Higher</span>
                          <span className="sign">&#62;</span>
                          <span>{alertDetail.oxHrHigher}</span>
                        </li>
                        <li>
                          <span className="txt">Lower</span>
                          <span className="sign">&#62;</span>
                          <span>{alertDetail.oxHrLower}</span>
                        </li>
                      </ul>
                      <div className="unit">
                        <span>bpm</span>
                      </div>
                    </div>
                  </div>
                </div>
              </article>

              <article>
                <h4>
                  <i className="fa fa-thermometer-three-quarters"></i>
                  <strong>Body Temperature</strong>
                </h4>
                <div className="level-wrap">
                  <div className="block">
                    <strong>Temperature</strong>
                    <div className="record-wrap">
                      <ul className="record">
                        <li>
                          <span className="txt">Higher</span>
                          <span className="sign">&#62;</span>
                          <span>{alertDetail.bodytempHigher}</span>
                        </li>
                        <li>
                          <span className="txt">Lower</span>
                          <span className="sign">&#62;</span>
                          <span>{alertDetail.bodytempLower}</span>
                        </li>
                      </ul>
                      <div className="unit">
                        <span>{alertDetail.bodytempUnit}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </article>
            </div>
          </div>

          <footer>
            <div className="btn-wrap">
              <Button
                className="btn md grey"
                text="Close"
                type="default"
                stylingMode="contained"
                onClick={() => $(".popClose").trigger("click")}
              />
            </div>
          </footer>
        </section>
      </div>
      <section className="sub-top">
        <header>
          <div className="page-history">
            <ul>
              <li>
                <a href="/main">
                  <i className="fa fa-home" aria-hidden="true"></i>
                  <span className="blind">home</span>
                </a>
              </li>
              <li className="current">
                <a href="/alert">
                  <span>Settings</span>
                </a>
              </li>
            </ul>
          </div>
          <h2>Alert Level</h2>
        </header>
        <article>
          <div className="flex-wrap mt30 pb10">
            <p className="info-txt">
              <span>Last Update :</span>
              <span>
                {moment(alertValue.inputUtcDt).format("MM/DD/YYYY hh:mm:ss")}
              </span>
            </p>
            <Button
              className="btn sm flex-mla"
              id="btnEdit"
              text="Edit"
              type="default"
              icon="fa fa-pencil"
              stylingMode="contained"
              onClick={() => {
                if (txtDisabled) {
                  setTxtDisabled(false);
                }
              }}
            />
          </div>
          <div className="tbl-wrap">
            <table className="type-ct" style={{ minWidth: 1000 }}>
              <caption>Summary</caption>
              <colgroup>
                <col width="15%" />
                <col width="15%" />
                <col />
                <col />
                <col width="15%" />
              </colgroup>
              <thead>
                <tr>
                  <th scope="colgroup" colSpan={2}>
                    Parameters
                  </th>
                  <th scope="colgroup" colSpan={2}>
                    Patient Specific Alert Thresholds
                  </th>
                  <th scope="col">Unit</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td scope="row" rowSpan={3} className="fst">
                    Blood Pressure
                  </td>
                  <td>Systolic</td>
                  <td>
                    <div className="form-wrap">
                      <span className="mr10">Higher &#62; </span>
                      <TextBox
                        id="txtInp1"
                        className="inp"
                        disabled={txtDisabled}
                        onValueChanged={(e: any) => {
                          valueChanged(e, 1);
                        }}
                      ></TextBox>
                    </div>
                  </td>
                  <td>
                    <div className="form-wrap">
                      <span className="mr10">Lower &#60; </span>
                      <TextBox
                        id="txtInp2"
                        className="inp"
                        disabled={txtDisabled}
                        onValueChanged={(e: any) => {
                          valueChanged(e, 2);
                        }}
                      ></TextBox>
                    </div>
                  </td>
                  <td>mmHg</td>
                </tr>
                <tr>
                  <td scope="row">Diastolic</td>
                  <td>
                    <div className="form-wrap">
                      <span className="mr10">Higher &#62; </span>
                      <TextBox
                        id="txtInp3"
                        className="inp"
                        disabled={txtDisabled}
                        onValueChanged={(e: any) => {
                          valueChanged(e, 3);
                        }}
                      ></TextBox>
                    </div>
                  </td>
                  <td>
                    <div className="form-wrap">
                      <span className="mr10">Lower &#60; </span>
                      <TextBox
                        id="txtInp4"
                        className="inp"
                        disabled={txtDisabled}
                        onValueChanged={(e: any) => {
                          valueChanged(e, 4);
                        }}
                      ></TextBox>
                    </div>
                  </td>
                  <td>mmHg</td>
                </tr>
                <tr>
                  <td scope="row">Heart Rate</td>
                  <td>
                    <div className="form-wrap">
                      <span className="mr10">Higher &#62; </span>
                      <TextBox
                        id="txtInp5"
                        className="inp"
                        disabled={txtDisabled}
                        onValueChanged={(e: any) => {
                          valueChanged(e, 5);
                        }}
                      ></TextBox>
                    </div>
                  </td>
                  <td>
                    <div className="form-wrap">
                      <span className="mr10">Lower &#60; </span>
                      <TextBox
                        id="txtInp6"
                        className="inp"
                        disabled={txtDisabled}
                        onValueChanged={(e: any) => {
                          valueChanged(e, 6);
                        }}
                      ></TextBox>
                    </div>
                  </td>
                  <td>bpm</td>
                </tr>
                <tr>
                  <td scope="row" rowSpan={2} className="fst">
                    SpO₂
                  </td>
                  <td>Oxygen Level</td>
                  <td>
                    <div className="form-wrap">
                      <span className="mr10">Higher &#62; </span>
                      <TextBox
                        id="txtInp7"
                        className="inp"
                        disabled={txtDisabled}
                        onValueChanged={(e: any) => {
                          valueChanged(e, 7);
                        }}
                      ></TextBox>
                    </div>
                  </td>
                  <td>
                    <div className="form-wrap">
                      <span className="mr10">Lower &#60; </span>
                      <TextBox
                        id="txtInp8"
                        className="inp"
                        disabled={txtDisabled}
                        onValueChanged={(e: any) => {
                          valueChanged(e, 8);
                        }}
                      ></TextBox>
                    </div>
                  </td>
                  <td>%</td>
                </tr>
                <tr>
                  <td scope="row">Heart Rate</td>
                  <td>
                    <div className="form-wrap">
                      <span className="mr10">Higher &#62; </span>
                      <TextBox
                        id="txtInp9"
                        className="inp"
                        disabled={txtDisabled}
                        onValueChanged={(e: any) => {
                          valueChanged(e, 9);
                        }}
                      ></TextBox>
                    </div>
                  </td>
                  <td>
                    <div className="form-wrap">
                      <span className="mr10">Lower &#60; </span>
                      <TextBox
                        id="txtInp10"
                        className="inp"
                        disabled={txtDisabled}
                        onValueChanged={(e: any) => {
                          valueChanged(e, 10);
                        }}
                      ></TextBox>
                    </div>
                  </td>
                  <td>bpm</td>
                </tr>
                <tr>
                  <td scope="row" className="fst">
                    Temperature
                  </td>
                  <td>Temperature</td>
                  <td>
                    <div className="form-wrap">
                      <span className="mr10">Higher &#62; </span>
                      <TextBox
                        id="txtInp11"
                        className="inp"
                        disabled={txtDisabled}
                        onValueChanged={(e: any) => {
                          valueChanged(e, 11);
                        }}
                      ></TextBox>
                    </div>
                  </td>
                  <td>
                    <div className="form-wrap">
                      <span className="mr10">Lower &#60; </span>
                      <TextBox
                        id="txtInp12"
                        className="inp"
                        disabled={txtDisabled}
                        onValueChanged={(e: any) => {
                          valueChanged(e, 12);
                        }}
                      ></TextBox>
                    </div>
                  </td>
                  <td>
                    <SelectBox
                      id="selTemp"
                      dataSource={["℉", "℃"]}
                      disabled={txtDisabled}
                      onValueChanged={(e: any) => {
                        valueChanged(e, 13);
                      }}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="flex-wrap mt10">
            <Button
              className="btn sm blue flex-mla"
              id="btnSave"
              text="Save"
              type="success"
              icon="fa fa-check"
              stylingMode="contained"
              onClick={btnSave}
            />
          </div>
        </article>
      </section>

      <section className="sub-cont">
        <header>
          <h2>
            <span>History</span>
          </h2>
          <div className="search-wrap">
            <div className="wrapper">
              <DateBox
                id="fromDate"
                value={startDate}
                max={endDate}
                displayFormat="yyyy-MM-dd"
                type="date"
                onValueChanged={onSDateChanged}
              />
              <span className="hyphen"> ~ </span>
              <DateBox
                id="toDate"
                value={endDate}
                displayFormat="yyyy-MM-dd"
                type="date"
                onValueChanged={onEDateChanged}
              />
            </div>
            <Button
              className="btn md blue ml10"
              id="btnSearch"
              text="Search"
              type="success"
              stylingMode="contained"
              icon="fa fa-search"
              onClick={onSearch}
            />
          </div>
        </header>

        <section className="sub-cont__wrap">
          <article>
            <div className="tbl-wrap">
              <DataGrid
                id="gridCont1"
                dataSource={alert}
                hoverStateEnabled={true}
                filterValue={gridFilterValue}
                className="type-ct"
                style={{ minWidth: 1000 }}
              >
                <Column
                  caption="No"
                  width={50}
                  cellRender={(e) => {
                    return e.row.rowIndex + 1;
                  }}
                />
                <Column
                  dataField="inputUtcDt"
                  caption="Date"
                  dataType="date"
                  format="yyyy-MM-dd"
                />
                <Column dataField="changeItem" caption="Change History" />

                <Column
                  dataField="Alert Level"
                  caption="Alert Level"
                  width={100}
                  cellTemplate={(container: any, options: any) => {
                    $("<a class='link' style='cursor:pointer'>")
                      .on("click", () => {
                        console.log(options.data);
                        setAlertDetail(options.data);
                        common.openLayer("popAlertLevel");
                      })
                      .append($("<i>", { class: "fa fa-file-text-o" }))
                      .appendTo(container);
                  }}
                />
              </DataGrid>
            </div>
          </article>
        </section>
      </section>
    </main>
  );
};

export default Alert;
