import axios from "axios";
import moment from "moment";

const server = "https://prevla.hicare.net";
//const server = "https://test.iot4health.co.kr";
// window.location.hostname === "adm.hicare.net"
//   ? "https://api.hicare.net"
//   : window.location.hostname === "dev.adm.hicare.net"
//   ? "https://dev.api.hicare.net"
//   : "http://localhost:3000";
console.log(server);

export class AdminDataProvider {
  public dateFormat(date: any) {
    let dateFormat2 = moment(date).format("YYYY-MM-DD");
    return dateFormat2;
  }

  // 유로스타 로그인 함수
  public async loginAdmin(id: any, pwd: any): Promise<any> {
    const resp = await axios
      .post(
        server + "/e/admin/login",
        { id: id, password: pwd },
        {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        }
      )
      .then(function (response) {
        return response;
      })
      .catch(function (error) {
        console.log("error: ", error);
      });
    console.log("Login: ", resp);
    return resp;
  }

  // 서버 세션 처리 함수
  public async jwtAdmin(): Promise<any> {
    const resp = await axios
      .post(server + "/e/admin/verify-jwt", {}, { withCredentials: true })
      .then(function (response) {
        return response;
      })
      .catch(function (error) {
        console.log("error: ", error);
      });
    console.log("jwt: ", resp);
    return resp;
  }

  // 로그아웃 함수
  public async logout(id: any): Promise<any> {
    const resp = await axios
      .post(
        server + "/e/admin/logout",
        { id: id },
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      )
      .then(function (response) {
        return response;
      })
      .catch(function (error) {
        window.location.href = "/auth/login";
        console.log("error: ", error);
      });
    console.log("Logout: ", resp);
    return resp;
  }

  // 패스워드 변경 함수
  public async PassWordChange(id: any, pw: any, newpw: any): Promise<any> {
    const resp = await axios
      .post(
        server + "/e/admin/password",
        { id: id, password: pw, newPassword: newpw },
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      )
      .then(function (response) {
        return response;
      })
      .catch(function (error) {
        console.log("error: ", error);
      });
    console.log("Password Change: ", resp);
    return resp;
  }

  // 재활치료 대시보드 함수
  public async searchRehabilitation(params: any): Promise<any> {
    const resp = await axios
      .get(server + "/rehabilitation/dashboard", {
        params: params,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        withCredentials: true,
      })
      .then(function (response) {
        return response;
      })
      .catch(function (error) {
        window.location.href = "/auth/login";
        console.log("error: ", error);
      });
    return resp;
  }

  // 재활치료 환자상세 함수
  public async getRehabilitationPatientDetail(params: any): Promise<any> {
    const resp = await axios
      .get(server + "/rehabilitation/patient/dashboard", {
        params: params,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        withCredentials: true,
      })
      .then(function (response) {
        return response;
      })
      .catch(function (error) {
        window.location.href = "/auth/login";
        console.log("error: ", error);
      });
    return resp;
  }

  // 재활치료 게임 상세 보기 함
  public async getRehabilitationGameDetail(params: any): Promise<any> {
    const resp = await axios
      .get(server + "/rehabilitation/patient/dashboard/info", {
        params: params,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        withCredentials: true,
      })
      .then(function (response) {
        return response;
      })
      .catch(function (error) {
        console.log("error: ", error);
      });
    return resp;
  }

  // 연하장애 대시보드 함수
  public async searchDysphagia(params: any): Promise<any> {
    const resp = await axios
      .get(server + "/dysphagia/dashboard", {
        params: params,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        withCredentials: true,
      })
      .then(function (response) {
        return response;
      })
      .catch(function (error) {
        window.location.href = "/auth/login";
        console.log("error: ", error);
      });
    return resp;
  }

  // 연하장애 환자상세 함수
  public async getDysphagiaPatientDetail(params: any): Promise<any> {
    const resp = await axios
      .get(server + "/dysphagia/patient/dashboard", {
        params: params,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        withCredentials: true,
      })
      .then(function (response) {
        return response;
      })
      .catch(function (error) {
        window.location.href = "/auth/login";
        console.log("error: ", error);
      });
    return resp;
  }

  // 연하장애 게임 상세 보기 함
  public async getDysphagiasGameDetail(params: any): Promise<any> {
    const resp = await axios
      .get(server + "/dysphagia/patient/dashboard/info", {
        params: params,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        withCredentials: true,
      })
      .then(function (response) {
        return response;
      })
      .catch(function (error) {
        console.log("error: ", error);
      });
    return resp;
  }

  public async AddAssessment(body: any): Promise<any> {
    const resp = await fetch(server + "/admin/assessment", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("jwt"),
      },
      body: JSON.stringify(body),
    });

    const data = await resp.json();
    return data;
  }

  public async GetAdminList(): Promise<any> {
    const resp = await fetch(server + "/admin/list", {
      method: "GET",
      headers: { Authorization: "Bearer " + localStorage.getItem("jwt") },
    });

    const data = await resp.json();
    return data;
  }

  public async GetMainList(): Promise<any> {
    const resp = await fetch(server + "/admin/main", {
      method: "GET",
      headers: { Authorization: "Bearer " + localStorage.getItem("jwt") },
    });

    const data = await resp.json();
    return data;
  }

  public async addDoctor(body: any): Promise<any> {
    const resp = await fetch(server + "/admin/doctor", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("jwt"),
      },
      body: JSON.stringify(body),
    });

    const data = await resp.json();
    return data;
  }

  public async addAdmin(body: any): Promise<any> {
    const resp = await fetch(server + "/admin/super", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("jwt"),
      },
      body: JSON.stringify(body),
    });

    const data = await resp.json();
    return data;
  }
  public async addDeviceRelation(body: any): Promise<any> {
    const resp = await fetch(server + "/admin/deviceRelation", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("jwt"),
      },
      body: JSON.stringify(body),
    });

    const data = await resp.json();
    return data;
  }
  public async addDoctorRelation(body: any): Promise<any> {
    const resp = await fetch(server + "/admin/doctorRelation", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("jwt"),
      },
      body: JSON.stringify(body),
    });

    const data = await resp.json();
    return data;
  }
  public async addAlert(body: any): Promise<any> {
    const resp = await fetch(server + "/admin/alert", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("jwt"),
      },
      body: JSON.stringify(body),
    });

    const data = await resp.json();
    return data;
  }
  public async addWebPage(body: any): Promise<any> {
    const resp = await fetch(server + "/admin/alert", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("jwt"),
      },
      body: JSON.stringify(body),
    });

    const data = await resp.json();
    return data;
  }
  public async DeleteDoctor(seq: any): Promise<any> {
    const resp = await fetch(server + "/admin/doctor/" + seq, {
      method: "DELETE",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("jwt"),
      },
    });

    const data = await resp.json();
    return data;
  }
  public async DeleteAdmin(seq: any): Promise<any> {
    const resp = await fetch(server + "/admin/" + seq, {
      method: "DELETE",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("jwt"),
      },
    });

    const data = await resp.json();
    return data;
  }
  public async addPatient(body: any): Promise<any> {
    const resp = await fetch(server + "/admin/patient", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("jwt"),
      },
      body: JSON.stringify(body),
    });

    const data = await resp.json();
    return data;
  }
  public async DeletePatient(seq: any): Promise<any> {
    const resp = await fetch(server + "/admin/patient/" + seq, {
      method: "DELETE",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("jwt"),
      },
    });

    const data = await resp.json();
    return data;
  }
  public async DeleteDeviceRelations(seq: any): Promise<any> {
    const resp = await fetch(server + "/admin/deviceRelations/" + seq, {
      method: "DELETE",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("jwt"),
      },
    });

    const data = await resp.json();
    return data;
  }
  public async DeleteDoctorPatientRelation(seq: any): Promise<any> {
    const resp = await fetch(server + "/admin/doctorPatientRelation/" + seq, {
      method: "DELETE",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("jwt"),
      },
    });

    const data = await resp.json();
    return data;
  }
  public async DeleteDevice(seq: any): Promise<any> {
    const resp = await fetch(server + "/admin/device/" + seq, {
      method: "DELETE",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("jwt"),
      },
    });

    const data = await resp.json();
    return data;
  }
}
