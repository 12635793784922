/* react */
import * as React from "react";
import { FunctionComponent, useEffect } from "react";

import { ILoginProps } from "./ILogin";
import $ from "jquery";
import { Common } from "../../lib/utils/common";
import "devextreme/dist/css/dx.light.css";
import "../../css/font-awesome.min.css";
import "../../css/layout.css";
// import "jquery-ui/themes/base/jquery-ui.css";
import { Button, LoadPanel, SelectBox, TextBox } from "devextreme-react";
import tBox from "devextreme/ui/text_box";
import { AdminDataProvider } from "../../lib/service/AdminDataProvider";
import notify from "devextreme/ui/notify";
import { useHistory } from "react-router-dom";
import { lang } from "../../lib/utils/language";
import { locale, loadMessages, formatMessage } from "devextreme/localization";
loadMessages(lang);
locale("en");
export const Login: FunctionComponent<ILoginProps> = (props) => {
  const history = useHistory();
  const common = new Common();
  const [panShow, setPanShow] = React.useState(Boolean);
  useEffect(() => {
    common.ResizePage();
    const fetchArticles = async () => {
      localStorage.setItem("lang", "en");
    };

    fetchArticles();
    if (localStorage.getItem("id") != null) {
      $("#txtId").val("" + localStorage.getItem("id"));
    }
  });
  const loginSubmit = async () => {
    const id = _dev("txtId", tBox).option("value");
    const pwd = _dev("txtPw", tBox).option("value");

    const adminService = new AdminDataProvider();
    setPanShow(true);
    await adminService.loginAdmin(id, pwd).then((d: any) => {
      if (d === undefined || d.status !== 200) {
        setPanShow(false);
        notify(formatMessage("msg_incorrect_password"), "Warning", 1000);
      } else {
        sessionStorage.setItem("name", id);
        sessionStorage.setItem("id", id);
        // 서버에서 세션 토큰을 처리하지 못할 경우
        // localStorage.setItem("access_token", d.data.access_token);
        setPanShow(false);

        if (id === "system") {
          window.location.href = "/superAdmin";
        } else {
          window.location.href = "/main";
        }
      }
    });

    // 서버 세션 처리
    await adminService.jwtAdmin().then((d: any) => {});
  };

  const _dev = (e: any, d: any) => {
    const id = document.getElementById(e) as HTMLElement;
    return d.getInstance(id);
  };
  // const changeLang = async (e: any) => {
  //   locale(e.value);
  //   localStorage.setItem("lang", e.value);
  //   history.push("/auth/login");
  // };
  return (
    <div className="comHeight">
      <div className="skip-navi">
        <a href="#content">본문 바로가기</a>
      </div>
      <React.Fragment>
        <LoadPanel
          shadingColor="rgba(0,0,0,0.4)"
          position={{ of: ".wrap" }}
          visible={panShow}
          showIndicator={true}
          shading={true}
          showPane={true}
          closeOnOutsideClick={false}
        />
      </React.Fragment>
      <div className="wrap login-page">
        <main id="content" className="content flex-ct">
          <section className="login">
            <header>
              {/* <SelectBox
                id="selName"
                className="lang"
                width={160}
                displayExpr="name"
                valueExpr="value"
                items={[
                  { name: "English(US)", value: "en" },
                  { name: "Korean", value: "ko" },
                ]}
                defaultValue={"en"}
                onValueChanged={changeLang}
              /> */}
            </header>

            <form action="" name="loginForm">
              <div className="login__wrap">
                <div className="login__form">
                  <div className="name_wrap">
                    <span className="blue">EUROSTAR</span>
                    <span className="navy">Integration</span>
                    <span className="grey">Platform</span>
                  </div>
                  <TextBox id="txtId" placeholder="ID" className="inp" />
                  <TextBox
                    id="txtPw"
                    mode="password"
                    placeholder="PASSWORD"
                    className="inp"
                    onEnterKey={loginSubmit}
                  />
                  <Button
                    id="signin"
                    className="btn lg blue full"
                    text={formatMessage("btn_sign_in")}
                    type="success"
                    stylingMode="contained"
                    onClick={loginSubmit}
                    // onClick={() => history.push("/main")}
                  />
                </div>
              </div>
            </form>
          </section>
        </main>
      </div>
    </div>
  );
};

export default Login;
