import * as React from "react";
import { FunctionComponent, useEffect, useState } from "react";
import DataGrid, {
  Column,
  ColumnChooser,
  Editing,
  Pager,
  Paging,
  SearchPanel,
} from "devextreme-react/data-grid";
import "devextreme/dist/css/dx.light.css";

import "../../css/font-awesome.min.css";
import "../../css/layout.css";
import "../../css/custom.css";
// import "jquery-ui/themes/base/jquery-ui.css";
import $ from "jquery";
import "jquery-ui";
import { Common } from "../../lib/utils/common";
import { useHistory } from "react-router-dom";
import { DateBox, SelectBox, TextBox } from "devextreme-react";
import * as _ from "lodash";
import { IMainProps } from "./IMain";

import moment from "moment";
import { AdminDataProvider } from "../../lib/service/AdminDataProvider";
import { lang } from "../../lib/utils/language";
import { locale, loadMessages, formatMessage } from "devextreme/localization";
loadMessages(lang);

export const Main: FunctionComponent<IMainProps> = (props) => {
  locale("" + localStorage.getItem("lang"));
  const history = useHistory();
  const common = new Common();
  const [deviceRelations, setDeviceRelations] = useState([] as any);
  const [devices, setDevices] = useState([] as any);
  const [doctorPatientRelation, setDoctorPatientRelation] = useState([] as any);
  const [doctor, setDoctor] = useState([] as any);
  const [patient, setPatient] = useState([] as any);
  const [videoConsultationHistory, setVideoConsultationHistory] = useState(
    [] as any
  );

  useEffect(() => {
    common.ResizePage();
    const fetchArticles = async () => {
      const adminService = new AdminDataProvider();
      const main = await adminService.GetMainList();
      console.log(main.data);
      setDeviceRelations(main.data.deviceRelations);
      setDevices(main.data.devices);
      setDoctorPatientRelation(main.data.doctorPatientRelation);
      setDoctor(main.data.doctor);
      setPatient(main.data.patient);
      setVideoConsultationHistory(main.data.videoConsultationHistory);
    };
    if (localStorage.getItem("id") === "system") {
      history.push("/superAdmin");
    } else if (sessionStorage.getItem("name") !== null) {
      // fetchArticles();
    } else {
      history.push("/auth/login");
    }
  }, [props]);

  return (
    <main id="content" className="content">
      <section className="main-area">
        {/* <div className="main-top">
          <p>
            <span>
              {formatMessage("txt_today_upper")} /{" "}
              {formatMessage("txt_total_upper")}
            </span>
          </p>
          <p className="date">
            <i className="fa fa-clock-o" aria-hidden="true"></i>
            <span>{moment().format("MM/DD/YYYY").toString()}</span>
          </p>
        </div> */}

        <div style={{ display: "flex", marginTop: "125px" }}>
          <div
            style={{
              width: "48%",
              height: "500px",
              border: "1px solid silver",
              color: "#0373c6",
              fontSize: "30px",
              cursor: "pointer",
              textAlign: "center",
              position: "relative",
            }}
            onClick={() => {
              history.push("/patient");
            }}
          >
            <div
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-40%, -50%)",
              }}
            >
              <i className="fa fa-calendar icon-img fa-2x"></i>
              <br />
              <br />
              <strong>PSERSONALIZED</strong>
              <br />
              <strong>REHABILITATION</strong>
              <br />
              <strong>SYSTEM</strong>
            </div>
          </div>
          <div
            style={{
              marginLeft: "25px",
              width: "48%",
              height: "500px",
              border: "1px solid silver",
              color: "#0373c6",
              fontSize: "30px",
              cursor: "pointer",
              textAlign: "center",
              position: "relative",
            }}
            onClick={() => {
              history.push("/dysphagia");
            }}
          >
            <div
              style={{
                position: "absolute",
                top: "40%",
                left: "50%",
                transform: "translate(-40%, -50%)",
              }}
            >
              <i className="fa fa-user-o icon-img fa-2x"></i>
              <br />
              <br />
              <strong>DYSPHAGIA</strong>
            </div>
          </div>
        </div>
        {/* 
        <dl>
          <dt>
            <strong>Step 1.</strong>
            <span> {formatMessage("txt_register_patient_device_medi")}</span>
          </dt>
          <dd>
            <article className="item">
              <header>
                <div className="num">
                  <a href="/patient" className="yellow">
                    {
                      _.filter(patient, function (o) {
                        return (
                          moment(o.inputUtcDt).format("YYYY-MM-DD") ===
                          moment().utc().format("YYYY-MM-DD")
                        );
                      }).length
                    }
                  </a>
                  <span className="txt">/ {patient.length}</span>
                </div>
              </header>
              <strong>{formatMessage("txt_patient")}</strong>
            </article>
          </dd>
          <dd>
            <article className="item">
              <header>
                <div className="num">
                  <a href="/patient" className="yellow">
                    {
                      _.filter(patient, function (o) {
                        return (
                          moment(o.inputUtcDt).format("YYYY-MM-DD") ===
                          moment().utc().format("YYYY-MM-DD")
                        );
                      }).length
                    }
                  </a>
                  <span className="txt">/ {patient.length}</span>
                </div>
              </header>
              <strong>{formatMessage("txt_patient")}</strong>
            </article>
          </dd>
        </dl> */}

        {/* <div className="grid-icon">
          <i className="fa fa-angle-down" aria-hidden="true"></i>
        </div> */}

        {/* <dl>
          <dt>
            <strong>Step 2.</strong>
            <span>{formatMessage("txt_matching_pm_pd")}</span>
          </dt>
          <dd>
            <article className="item">
              <header>
                <div className="num">
                  <a href="/videoHistory" className="green">
                    {
                      _.filter(videoConsultationHistory, function (o) {
                        return (
                          moment(o.startUtcDt).format("YYYY-MM-DD") ===
                          moment().utc().format("YYYY-MM-DD")
                        );
                      }).length
                    }
                  </a>
                  <span className="txt">
                    / {videoConsultationHistory.length}
                  </span>
                </div>
              </header>
              <strong>{formatMessage("txt_videocall")}</strong>
            </article>
            <span className="grid-bar"></span>
            <article className="item">
              <header>
                <div className="num">
                  <a href="/medicalLink" className="green">
                    {
                      _.filter(doctorPatientRelation, function (o) {
                        return (
                          moment(o.inputUtcDt).format("YYYY-MM-DD") ===
                          moment().utc().format("YYYY-MM-DD")
                        );
                      }).length
                    }
                  </a>
                  <span className="txt">/ {doctorPatientRelation.length}</span>
                </div>
              </header>
              <strong>{formatMessage("txt_webchart")}</strong>
            </article>
            <span className="grid-bar"></span>
            <article className="item lst">
              <header>
                <div className="num">
                  <a href="/deviceLink" className="blue">
                    {
                      _.filter(deviceRelations, function (o) {
                        return (
                          moment(o.inputUtcDt).format("YYYY-MM-DD") ===
                          moment().utc().format("YYYY-MM-DD")
                        );
                      }).length
                    }
                  </a>
                  <span className="txt">/ {deviceRelations.length}</span>
                </div>
              </header>
              <strong>{formatMessage("txt_devices")}</strong>
            </article>
          </dd>
        </dl> */}
      </section>
    </main>
  );
};

export default Main;
